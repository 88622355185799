@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

$font-base          : 1.4rem;
$font-header        : 'Montserrat', sans-serif;
$font-body          : 'Montserrat', sans-serif;
$font-icon          : 'Material Icons';

$color-light        : #FFFFFF;
$color-dark         : #4C4D5D;
$color-grey-dark    : #797979;
$color-primary      : #0052CC;
$color-primary-dark : #003b94;
$color-primary-light: #cae8ff;
$color-secondary    : #ffb82a;
$color-secondary-light  : #fdebc9;
$color-danger       : #FF5E5E;
$color-danger-light       : #ffe8e8;
$color-border       : #ececec;
$color-grey         : #93949F;

$h1                 : $font-base * 3.2;
$h2                 : $font-base * 2.2;
$h3                 : $font-base * 1.4;
$h4                 : $font-base * 1.2;
$h5                 : $font-base;
$h6                 : $font-base * 0.9;

$weight-normal      : 400;
$weight-semi        : 500;
$weight-bold        : 700;

$el-mar             : 2rem;
$el-pad             : 2rem;
$el-rad             : .5rem;

$lh                 : 1.4;

$z-navbar           : 101;
$z-tabbar           : 100;
$z-nav              : 101;
$z-alert            : 104;

$el-height          : 5rem;

// wrapper
$bg                 : $color-light;

// Navigation
$nav-height         : 7rem;
$nav-width          : 30rem;
$nav-bg             : $color-light;
$nav-icon           : 2.8rem;
$nav-label          : 1.2rem;
$nav-label-weight   : $weight-semi;
$nav-color          : $color-grey-dark;
$nav-color-icon     : #BEBFC5;
$nav-color-active   : $color-primary;

// Header
$header-height      : 9rem;
$z-header           : 100;

// Tab bar
$tabbar-height      : 6rem;

// Subsc
$sub-height         : 20rem;
$sub-title          : 4.8rem;
$sub-desc           : 1.6rem;
$sub-action         : 1.4rem;

// Input
$label-height       : 1.5rem;
$label-margin       : .5rem;
$label              : $font-base * 0.8;
$label-spc          : .1rem;
$input-height       : $el-height;

// Modal
$z-modal : 103;
$modal-height : $el-height;

// Wrapper
$phone              : "(max-device-width: 767px) and (orientation: portrait)";
$phone-ls           : "(max-device-width: 767px) and (orientation: landscape)";
$tablet             : "(min-width: 768px) and (max-width: 1199px)";
$desktop            : "(min-width: 1200px)";