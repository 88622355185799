@import '../../../config/theme';

.inputContainer{
  position: relative;
}

.input{
  &__label{
    display:block;
    width: 100%;
    min-height: $label-height;
    color: $color-dark;
    font-size: $font-base;
    margin-bottom: 1rem;
    font-weight: $weight-normal;

    .input__label__required{
      margin-left: $el-pad/2;
      color: $color-danger;
    }
  }

  &__wrapper{
    position: relative;
    display: flex;
    
    &__icon{
      display: none;
    }

    &__input, &__file{
      width: 100%;
      height: $input-height;
      border-radius: $el-rad;
      box-sizing: border-box;
      border: 2px solid $color-border;
      background: $color-light;
      padding: $el-pad;
      font-size: $font-base;
      outline: none;
      transition: border .2s ease;
  
      &:disabled{
        background-color: darken($color-light, 10);
        border-color: darken($color-light, 10);
      }
  
      &:focus{
        border-color: $color-primary;
      }
  
      &:-webkit-autofill,
      &:-webkit-autofill:hover, 
      &:-webkit-autofill:focus{
        background-color: $color-light !important;
        box-shadow: 0 0 1000rem inset $color-light
      }
    }

    &__file{
      padding: $el-pad/2 $el-pad;
      visibility: hidden;
      position: absolute;
      text-indent: -99999px;
    }

    &__take-photo{
      position: relative;
      width: 100%;

      img{
        max-width: 100%;
        border-radius: $el-rad;
      }
    }

    textarea{
      height: unset;
      min-height: $el-height;
      font-family: inherit;
      font-size: inherit;
      padding: $el-pad/2 $el-pad;
      resize: vertical;
    }

    &__actions{
      display: flex;
      height: 100%;
      align-items: center;
      position: absolute;
      padding: 0 $el-pad/2;
      right: 0;
    }

    &__checkbox{
      opacity: 0;
      text-indent: -99999rem;
      position: absolute;

      &:checked + .input__wrapper__checkbox-label{
        &:before{
          content: 'check';
          font-family: $font-icon;
          background-color: $color-primary;
          border-color: $color-border;
          transition: background-color .2s ease;
        }
      }

      &:disabled + .input__wrapper__checkbox-label{
        &:before{
          background-color: $color-grey;
        }
      }
    }

    &__radio{
      opacity: 0;
      text-indent: -99999rem;
      position: absolute;

      &:checked + .input__wrapper__radio-label{
        position: relative;

        &:before{
          content: '';
          font-family: $font-icon;
          background-color: $color-light;
          border-color: $color-primary;
          transition: background-color .2s ease;
          width: $el-height/2;
          height: $el-height/2;
        }

        &:after{
          left: .6rem;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          content: '';
          width: $el-height/4;
          height: $el-height/4;
          background: $color-primary;
          position: absolute;
          border-radius: 100px;
        }
      }

      &:disabled + .input__wrapper__radio-label{
        &:before, &:after{
          opacity: .5;
        }
      }
    }

    &__radio-label{
      position: relative;
      display: flex;
      align-items: center;
      font-weight: $weight-semi;
      z-index: 5;
      padding-left: $el-height/1.5;
      min-height: $el-height/2;

      &:before{
        content: '';
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $el-height/3;
        margin-right: $el-pad/2;
        width: $el-height/2;
        height: $el-height/2;
        border-radius: 100px;
        border: 2px solid $color-border;
        color: $color-light;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto auto;
        left: 0;
      }
    }

    &__select{
      width: 100%;
      height: $input-height;
      border-radius: $el-rad;
      box-sizing: border-box;
      border: 2px solid $color-border;
      background: $color-light;
      padding: 0 $el-pad/2;
      font-size: $font-base;
      outline: none;
      transition: border .2s ease;
    }

    &__multi-select{
      width: 100%;
    
      &__control{
        min-height: $el-height - $el-pad !important;
        border: 2px solid $color-primary !important;
        box-sizing: content-box;
        padding: $el-pad/4 $el-pad/2;

        &--is-focused{
          border-color: $color-primary !important;
          box-shadow: none !important;
          border-width: 2px !important;
        }
      }

      &__menu{
        margin: 0 !important;
        border: none !important;
        box-shadow: none !important;
        border: 2px solid $color-border !important;
        box-shadow: 0 5px 20px rgba(0, 0, 0, .1) !important;
        box-sizing: border-box;
        border-radius: 4px !important;
        overflow: hidden;
        z-index: 10 !important;
      }
    
      &__menu-list{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      &__value-container{
        padding: 0.5rem 0 !important;
        
        &--is-multi{
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          height: auto !important;
        }
      }

      &__indicators{
        align-self: flex-start !important;
        max-height: $el-height - $el-pad;
      }
      
      &__indicator-separator{
        display: none;
      }
    
      &__option{
        padding: $el-pad/1.5 $el-pad/2 !important;
        color: $color-dark  !important;
        cursor: pointer !important;
        font-size: $font-base !important;
    
        &:hover{
          &:not(.select__option--is-selected){
            background: $color-primary-light !important;
          }
        }
    
        &--is-focused{
          color: $color-light !important;
          background: $color-primary !important;
        }

        &--is-selected{
          color: $color-light !important;
          background: $color-primary !important;
        }
      }
    
      &__multi-value{
        display: flex;
        align-items: center;
        height: $el-height - 2.5rem !important;
        border-radius: $el-rad !important;
        background: $color-border !important;
        color: $color-grey !important;
    
        &__label{
          font-size: $font-base !important;
          padding: 0 $el-pad/2 !important;
          color: $color-dark !important;
          font-weight: $weight-semi;
        }
      }
    }

    &__checkbox-label{
      position: relative;
      display: flex;
      align-items: center;
      font-weight: $weight-semi;
      z-index: 5;

      &:before{
        content: '';
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $el-height/3;
        margin-right: $el-pad/2;
        width: $el-height/2;
        height: $el-height/2;
        border-radius: $el-rad;
        border: 2px solid $color-border;
        color: $color-light;
        box-sizing: border-box;
      }
    }
  }

  &--with-icon{
    .input__wrapper{
      &__icon{
        display: flex;
        height: 100%;
        align-items: center;
        position: absolute;
        padding: 0 $el-pad/2;
        left: 0;

        &--secondary{
          color: $color-secondary;
        }
      }
      &__input{
        padding-left: $el-pad * 2;
      }
    }
    .input__wrapper{
      &__suffix{
        display: flex;
        height: 100%;
        align-items: center;
        position: absolute;
        padding: 0 $el-pad/2;
        right: 0;

        &--secondary{
          color: $color-secondary;
        }
      }
    }

    &--secondary{
      .input__wrapper__icon i{
        color: $color-secondary;
      }
    }

    &--danger{
      .input__wrapper__icon i{
        color: $color-danger;
      }
    }
  }

  &__error{
    font-size: $label;
    color: $color-danger;
    padding-left: $el-pad/2;
  }
}

.DateRangePicker_picker{
  z-index: 104;

  .CalendarDay__selected_start, .CalendarDay__selected_end {
    background: $color-primary !important;
    color: $color-light !important;
    border-color: $color-primary !important;
  }

  .CalendarDay__selected_span{
    background: $color-primary-light;
    color: $color-primary;
    border-color: $color-primary;
  }

  .DateRangePicker_closeButton{
    left: 0;
  }
  
  .DayPicker_transitionContainer{
    .CalendarMonthGrid{
      .CalendarDay{
        vertical-align: middle;
      }
    }
  }

  .DayPickerNavigation{
    display: flex;  
    height: $el-height;

    .DayPickerNavigation_button{
      box-shadow: none;
      background: $color-light;
      height: $el-height;
      padding: 0;
      align-items: center;
      justify-content: center;
      display: flex;
      border-color: $color-border;
      bottom: 0;
      box-sizing: border-box;

      svg{
        height: $el-height/2;
        fill: $color-dark;
      }
    }

  }
}

.DateRangePicker{
  width: 100%;
  .DateRangePickerInput{
    width: 100%;
    height: $input-height;
    border-radius: $el-rad;
    box-sizing: border-box;
    border: 2px solid $color-primary;
    background: $color-light;
    padding: 0 $el-pad;
    outline: none;
    transition: border .2s ease;
    display: flex;
    align-items: center;
    justify-content: stretch;

    .DateRangePickerInput_arrow{
      padding: 0 $el-pad;
    }
    
    .DateInput{
      border: none;
      background: none;
      height: $input-height;
      width: unset;

      .DateInput_input{
        padding: 0;
        height: $input-height;
        line-height: 1;
        border: none;
        background: none;
        font-size: $h5;
        font-weight: $weight-semi;
      }
    }
  }
}