@import "../../../config/_theme.scss";

.modal-overlay{
  background-color: rgba($color-dark, .5) !important;
  z-index: $z-modal;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &--transparent{
    background: rgba(0, 0, 0, 0.7) !important;
  }
}

.ReactModalPortal{
  overscroll-behavior-y: contain;
  overflow-y: auto;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  overflow: hidden;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
  
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.modal {
  overflow: hidden;
  position: absolute;
  z-index: $z-modal+1;
  padding: 0;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: $el-rad;
  border: none;
  width: calc(100% - 6rem);
  max-width: 50rem;
  height: calc(100% - 9rem);
  max-height: 20rem;
  background: $color-light;
  height: auto;

  .button--button-close-card{
    position: absolute;
    top: -#{$el-height};
    left: $el-pad/2;
    color: $color-light;
    text-align: center;
    padding-left: $el-pad/2;
    padding-right: $el-pad/2;
    
    i{
      margin-right: 0;
      font-size: 2.4rem;
    }
  }

  &--el{
    width: calc(100% - 6rem);
    max-width: 50rem;
    height: calc(100% - 9rem);
    max-height: 50rem;

    .modal__body{
      height: calc(100% - 4.5rem);
    }
  }

  &--transparent{
    background: transparent !important;
  }

  &--card{
    width: 100%;
    bottom: 0;
    height: calc(100% - (#{$header-height} - 1.5rem));
    max-height: none;
    max-width: none;
    transform: translate(-50%, 0);
    border-radius: $el-rad*4 $el-rad*4 0 0;
    overflow: visible;
  }

  &__header {
    background: $color-light;
    height: $modal-height;
    display: flex;
    align-items: center;

    &__text {
      font-weight: $weight-semi;
      align-items: center;
      height: $el-pad;
      padding: $el-pad;
      font-size: $font-base;
      width: calc(100% - 6.5rem);
      line-height: 1.2;
    }

    &__icon {
      display: inline-block;

      i {
        vertical-align: middle;
        color: $color-grey;
      }
    }
  }

  &__body {
    display: block;
    height: calc(100% - 12rem);
    overflow-y: auto;

    &__content{
      padding: $el-pad;
      line-height: $lh;

      a{
        color: $color-primary;
      }
    }
  }

  &__action {
    display: flex;
    align-content: center;
    justify-content: center;

    button{
      min-width: 50%;
      font-size: 1.4rem;
      font-weight: $weight-bold;

      &.button--modal-close{
        color: $color-danger;
      }
    }
  }
}

.ReactModal__Content {
}

.ReactModal__Content--after-open {
}

.ReactModal__Content--before-close {
}